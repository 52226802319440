const listConnectAvailable = [
  { hub: 'channelAdvisor', target: 'shopify' },
  { hub: 'channelAdvisor', target: 'woocommerce' },
  { hub: 'channelAdvisor', target: 'magento2' },
  { hub: 'channelAdvisor', target: 'peoplevox' },
  { hub: 'channelAdvisor', target: 'bigcommerce' },
  { hub: 'shopify', target: 'channelAdvisor' },
  { hub: 'shopify', target: 'esty' },
  { hub: 'shopify', target: 'tiktok' },
  { hub: 'magento2', target: 'channelAdvisor' },
  { hub: 'bigcommerce', target: 'channelAdvisor' },
  { hub: 'woocommerce', target: 'channelAdvisor' },
  { hub: 'esty', target: 'hypermule' },
  { hub: 'tiktok', target: 'shopify' },
  { hub: 'alphabroder', target: 'channelAdvisor' },
  { hub: 'linnworks', target: 'channelAdvisor' },
  { hub: 'quickbook', target: 'channelAdvisor' },
  { hub: 'prestashop', target: 'channelAdvisor' },
  { hub: 'visualsoft', target: 'channelAdvisor' },
  { hub: 'lightspeed', target: 'channelAdvisor' },
  { hub: 'brightpearl', target: 'channelAdvisor' },
  { hub: 'essendant', target: 'channelAdvisor' },
  { hub: 'odoo', target: 'woocommerce' },
  { hub: 'odoo', target: 'channelAdvisor' },
  { hub: 'xentral', target: 'channelAdvisor' },
  { hub: 'netsuite', target: 'channelAdvisor' },
  { hub: 'lightspeedX', target: 'channelAdvisor' },
  { hub: 'xero', target: 'channelAdvisor' },
  { hub: 'magento2', target: 'shopify' },
  { hub: 'apprise', target: 'channelAdvisor' },
  { hub: 'aims360', target: 'channelAdvisor' },
  { hub: 'xentral', target: 'mebel' },
  { hub: 'mebel', target: 'xentral' },
  { hub: 'portica', target: 'channelAdvisor' },
  { hub: 'sapb1', target: 'channelAdvisor' },
  { hub: 'dynamics365', target: 'channelAdvisor' },
  { hub: 'quickbook', target: 'teapplix' },
  { hub: 'channelAdvisor', target: 'dynamics365' },
  { hub: 'quickbookDesktop', target: 'channelAdvisor' },
  { hub: 'sapb1', target: 'warner' },
  { hub: 'myflex', target: 'channelAdvisor' },
  { hub: 'dynamicsax', target: 'channelAdvisor' },
  { hub: 'channelAdvisor', target: 'convictional' },
]

export default 
  listConnectAvailable
